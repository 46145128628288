import React, {useEffect, useRef} from "react";
import {PostHogProvider} from "posthog-js/react";
import {posthog} from "posthog-js";
import {globalHistory} from '@reach/router';

interface PostHogWrapperProps {
    children?: React.ReactNode
}

// Check that PostHog is client-side (used to handle Gatsby SSR)
if (typeof window !== 'undefined' && process.env.GATSBY_POSTHOG_KEY) {
    posthog.init(process.env.GATSBY_POSTHOG_KEY, {
        api_host: process.env.GATSBY_POSTHOG_HOST || 'https://app.posthog.com',
        // Enable debug mode in development
        loaded: (posthog) => {
            if (process.env.NODE_ENV === 'development') posthog.debug()
        }
    })
}

const PostHogWrapper = ({children }: PostHogWrapperProps) => {

    if (!process.env.GATSBY_POSTHOG_KEY) {
        return children
    }
    const lastPathRef = useRef('');

    useEffect(() => {
        if(!posthog) return
        // Track page views
        const handleRouteChange = (path: string) => {
            if (path !== lastPathRef.current) {
                posthog.capture('$pageview');
                lastPathRef.current = path;
            }
        }

        // Listen for changes
        const exit = globalHistory.listen(({ action, location }) => {
            console.log(location)
            if (action === 'PUSH') {
                handleRouteChange(location.pathname);
            }
        });

        // Clean up the listener when the component is unmounted
        return () => {
            exit();
        };
    }, [])

    return (
        <>
            <PostHogProvider
                client={posthog}
            >
                {children}
            </PostHogProvider>
        </>
    )
}

export default PostHogWrapper