import * as React from "react";
import Header from "@organisms/layout/header";
import {Box, Container} from "@mui/material";
import KookoAppButton from "@atoms/layout/kookoAppButton";
import Footer from "@organisms/layout/footer";
import {PageProps} from "gatsby";

interface LayoutProps {
    children: React.ReactElement<any, string | React.JSXElementConstructor<any>>,
    props: PageProps<Record<string, unknown>, Record<string, unknown>>
}

const Layout = ({children, props}: LayoutProps) => {
    const isPortal = props.path.includes("/portal")
    //@ts-ignore
    const isEvent = props.path.includes("/events") && props.data.strapiEvent?.restaurants?.find(restaurant => restaurant.menu) //Check if event has restaurants with menus

    return (
        <>
            <Header isPortal={isPortal} isEvent={isEvent}/>
            <Box component={"main"} pt={{xs: 8, sm: 10, lg: 12}} pb={6}>
                <Container maxWidth={"xl"} sx={{pb: 6, minHeight: "60vh"}}>
                    {children}
                </Container>
                <KookoAppButton sx={{display: {xs: "flex", md: "none"}}} isPortal={isPortal} isEvent={isEvent}/>
            </Box>
            <Footer/>
        </>
    )
}

export default Layout;