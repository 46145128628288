import * as React from "react";
import {Box, Collapse, Divider, Drawer, List, ListItem, ListItemText, Stack} from "@mui/material";
import {IconButton, Link, ListItemButton} from "gatsby-material-ui-components";
import Logo from "@molecules/logo";
import {useLocation} from "@reach/router"
import {getSlug, matchGatsbyPath, matchGatsbySubPath} from "@global/utils/regex/linkRegex";
import {ExpandLess, ExpandMore} from "@mui/icons-material";

const DrawerListItem: React.FunctionComponent<{
    link: Queries.StrapiNavigation,
    path: string | null,
    handleClose: any
}> = ({
          link,
          path,
          handleClose,
      }) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const location = useLocation()
    const active = matchGatsbySubPath(link, location)

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <>
            <ListItemButton
                selected={active}
                sx={{pt: 1, pb: 2}}
                onClick={link.navigationChildren && link.navigationChildren.length > 0 ? handleClick : () => {
                }}
            >
                <Link
                    //onClick={link.navigationChildren && link.navigationChildren.length > 0 ? () => {} : handleClose}
                    //to={"#"}
                    sx={{display: "flex", textDecoration: "none", width: "100%", height: 25, lineHeight: 1.3}}
                >
                    <ListItemText primary={link.title} primaryTypographyProps={{fontSize: 20}}/>
                </Link>
                {link.navigationChildren && link.navigationChildren.length > 0 &&
                    <IconButton onClick={handleClick}>{open ? <ExpandLess/> : <ExpandMore/>}</IconButton>}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {link.navigationChildren && link.navigationChildren.map((childLink, index) => {
                        if (!childLink) {
                            return
                        }
                        const childPath = getSlug(childLink)
                        const regex = childPath && matchGatsbyPath(childPath)
                        const childActive = regex ? regex.test(location.pathname) : false
                        return (
                            <ListItemButton
                                key={index}
                                selected={childActive}
                                sx={{
                                    pl: 4,
                                    pb: 2,
                                    borderRadius: "0 25px 25px 0",
                                    "&.Mui-selected": {
                                        backgroundColor: "var(--mui-palette-secondary-main)",
                                        "& div": {
                                            color: "var(--mui-palette-secondary-contrastText)"
                                        }
                                    },
                                    "&.Mui-selected:hover": {
                                        backgroundColor: "var(--mui-palette-primary-main)",
                                        "& span": {
                                            color: "var(--mui-palette-common-white)"
                                        }
                                    }
                                }}
                            >
                                <Link
                                    onClick={handleClose}
                                    to={childPath ?? undefined}
                                    sx={{
                                        display: "flex",
                                        textDecoration: "none",
                                        width: "100%",
                                        height: 25,
                                        lineHeight: 1.3
                                    }}
                                >
                                    <ListItemText primary={childLink.title} primaryTypographyProps={{fontSize: 20}}/>
                                </Link>
                            </ListItemButton>
                        )
                    })}
                </List>
            </Collapse>
        </>
    )
}

const DrawerContent: React.FunctionComponent<{
    navItems: Queries.StrapiNavigation[],
    handleDrawerToggle: () => void
}> = ({navItems, handleDrawerToggle}) => {
    const location = useLocation()

    return (
        <Stack pt={2}>
            <Logo/>
            <Divider sx={{mt: 2}}/>
            <List>
                {navItems.map((link) => {
                    const path = getSlug(link)
                    const regex = path && matchGatsbyPath(path)
                    const active = regex ? regex.test(location.pathname) : false
                    if (link.navigationChildren && link.navigationChildren?.length > 0) {
                        return (
                            <DrawerListItem key={link.id} link={link} path={path} handleClose={handleDrawerToggle}/>
                        )
                    }
                    if (!link.menuAttached || link.parentNode?.id) {
                        return
                    }
                    return (
                        <ListItem key={link.id} sx={{pl: 0}}>
                            <ListItemButton
                                onClick={handleDrawerToggle}
                                selected={active}
                                to={path ?? undefined}
                                sx={{
                                    borderRadius: "0 25px 25px 0",
                                    "&.Mui-selected": {
                                        backgroundColor: "var(--mui-palette-secondary-main)",
                                        "& div": {
                                            color: "var(--mui-palette-secondary-contrastText)"
                                        }
                                    },
                                    "&.Mui-selected:hover": {
                                        backgroundColor: "var(--mui-palette-primary-main)",
                                        "& span": {
                                            color: "var(--mui-palette-common-white)"
                                        }
                                    }
                                }}
                            >
                                <ListItemText primary={link.title} primaryTypographyProps={{fontSize: 20}}/>
                            </ListItemButton>
                        </ListItem>
                    )
                })}
            </List>
        </Stack>
    )
};

const MobileNav: React.FunctionComponent<{
    links: Queries.StrapiNavigation[],
    handleDrawerToggle: () => void
    open: boolean
    drawerWidth?: number | string
}> = ({links, handleDrawerToggle, open, drawerWidth}) => {
    return (
        <Box component="nav">
            <Drawer
                variant="temporary"
                open={open}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: {xs: 'block', sm: 'none'},
                    '& .MuiDrawer-paper': {
                        boxSizing: 'border-box',
                        width: drawerWidth,
                        backgroundColor: "var(--mui-palette-background-default)",
                    },
                }}
            >
                <DrawerContent navItems={links} handleDrawerToggle={handleDrawerToggle}/>
            </Drawer>
        </Box>
    )
}

export default MobileNav;