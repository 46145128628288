import * as React from "react";
import {Button, Link} from "gatsby-material-ui-components";
import {getSlug, matchGatsbyPath} from "@global/utils/regex/linkRegex";
import {useLocation} from "@reach/router";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {ArrowRightAlt} from "@mui/icons-material";

const PageListMenu: React.FunctionComponent<{ links: Queries.StrapiNavigation[] }> = ({
                                                                                          links
                                                                                      }) => {
    const location = useLocation()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                startIcon={<ArrowRightAlt/>}
            >
                Accès rapide
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {links.map((link, index) => {
                    const path = getSlug(link)
                    const regex = path && matchGatsbyPath(path)
                    const active = regex ? regex.test(location.pathname) : false
                    if (link.navigationChildren && link.navigationChildren?.length > 0) {
                        return
                    }
                    return (
                        <MenuItem
                            key={index}
                            onClick={handleClose}
                        >
                            <Link to={path ?? undefined}>{link.title}</Link>
                        </MenuItem>)
                })}
            </Menu>
        </>
    );
}
export default PageListMenu