import * as React from 'react';
import {Button, Link, ListItemButton} from "gatsby-material-ui-components";
import {getSlug, matchGatsbyPath, matchGatsbySubPath} from "@global/utils/regex/linkRegex";
import {useLocation} from "@reach/router";
import {Collapse, List, Stack} from "@mui/material";
import Box from "@mui/material/Box";

const NavList: React.FunctionComponent<{ link: Queries.StrapiNavigation, path: string | null }> = ({
                                                                                                       link,
                                                                                                       path,
                                                                                                   }) => {
    const location = useLocation()
    const active = matchGatsbySubPath(link, location)
    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <>
            <Stack onMouseLeave={() => setOpen(false)}>
                <Button
                    onMouseOver={handleClick}
                    aria-label={`navigate to ${link.title}`}
                    key={link.id}
                    //to={"#"}
                    sx={{
                        my: 2,
                        fontWeight: active ? 'bold' : 'normal',
                        color: active ? 'highContrast.main' : 'primary.contrastText',
                        display: 'block',
                        textAlign: 'center'
                    }}
                >
                    {link.title}
                </Button>
                <Collapse in={open} timeout={300}>
                    <List component="div" disablePadding onMouseLeave={() => setOpen(false)}>
                        {link.navigationChildren && link.navigationChildren.map((childLink, index) => {
                            if (!childLink) {
                                return
                            }
                            const childPath = getSlug(childLink)
                            const regex = childPath && matchGatsbyPath(childPath)
                            const childActive = regex ? regex.test(location.pathname) : false
                            return (
                                <Box width={"100%"} height={40} position={"relative"} top={-10} key={index}>
                                    <ListItemButton
                                        aria-label={`navigate to ${childLink.title}`}
                                        key={childLink.id}
                                        selected={childActive}
                                        sx={{
                                            borderRadius: 100,
                                            py: 0,
                                            position: "absolute",
                                            minWidth: 150,
                                            minHeight: 35,
                                            opacity: open ? 1 : 0,
                                            transition: open ? `opacity .4s ease 0.${index + 3}s` : "unset",
                                        }}
                                    >
                                        <Link
                                            to={childPath ?? undefined}
                                            sx={{
                                                py: 1,
                                                width: "100%",
                                                fontWeight: childActive ? 'bold' : 'normal',
                                                color: childActive ? 'highContrast.main' : 'primary.contrastText',
                                                textDecoration: "none",
                                                textAlign: "left"
                                            }}
                                            //className={open ? navListItem : ""}
                                        >
                                            {childLink.title}
                                        </Link>
                                    </ListItemButton>
                                </Box>
                            )
                        })}
                    </List>
                </Collapse>
            </Stack>
        </>
    );
}

export default NavList