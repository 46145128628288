import * as React from "react";
import {Link} from "gatsby-material-ui-components";
import {getSlug, matchGatsbyPath} from "@global/utils/regex/linkRegex";
import {useLocation} from "@reach/router";
import Grid from "@mui/material/Unstable_Grid2";
import {Stack} from "@mui/material";
import Typography from "@mui/material/Typography";

const PageListGrid: React.FunctionComponent<{ links: Queries.StrapiNavigation[] }> = ({
                                                                                          links
                                                                                      }) => {
    const location = useLocation()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Grid container spacing={4} sx={{textAlign: "right"}}>
            {links.map((link, index) => {
                const path = getSlug(link)
                const regex = path && matchGatsbyPath(path)
                const active = regex ? regex.test(location.pathname) : false
                let children = <React.Fragment/>
                if (link.navigationChildren && link.navigationChildren?.length > 0) {
                    children = (
                        <Stack spacing={1}>
                            {link.navigationChildren &&
                                link.navigationChildren.map((childLink, index) => {
                                    if (!childLink) {
                                        return
                                    }
                                    const childPath = getSlug(childLink)
                                    const regex = childPath && matchGatsbyPath(childPath)
                                    const childActive = regex ? regex.test(location.pathname) : false
                                    return (
                                        <Link key={index} to={childPath ?? undefined}
                                              color="secondary">{childLink.title}</Link>
                                    )
                                })
                            }
                        </Stack>
                    )
                }
                if (link.parentNode?.id) {
                    return
                }
                return (
                    <Grid key={index} minWidth={120} flexGrow={1}>
                        <Stack spacing={1}>
                            {link.navigationChildren && link.navigationChildren.length > 0 ?
                                <Typography fontWeight={"bold"}>{link.title}</Typography>
                                : <Link fontWeight={"bold"} to={path ?? undefined} color="secondary">{link.title}</Link>
                            }
                            {children}
                        </Stack>
                    </Grid>
                )
            })}
        </Grid>
    );
}

export default PageListGrid;