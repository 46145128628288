function regexValidation(exp: RegExp) {
    let isValid = true;
    try {
        new RegExp(exp);
    } catch (e) {
        console.error(e)
        isValid = false;
    }
    return isValid
}

export function getSlug(link: Queries.StrapiNavigation) {
    let path = link.path ? link.path === "/" ? link.path : `/${link.path}` : null
    const relatedSlug = link.related?.slug
    if (relatedSlug) {
        path = relatedSlug === "/" ? relatedSlug : `/${relatedSlug}`
    }
    return path
}

export function isCurrentGatsbyPath(path: string, locationPathname: string) {
    return path === locationPathname || locationPathname === `${path}/`
}

export function matchGatsbyPath(path: string) {
    const expression = `^${path}((\\/w+)+|\\/?)$`
    const regex = new RegExp(expression, "gm");
    if (!regexValidation(regex)) {
        return undefined
    }
    return regex
}

export function matchGatsbySubPath(link: Queries.StrapiNavigation, location: any) {
    let match = false
    if (!link.navigationChildren) {
        return match
    }
    link.navigationChildren.map((childLink) => {
        if (!childLink) {
            return
        }
        const childPath = getSlug(childLink)
        const regex = childPath && matchGatsbyPath(childPath)
        const childActive = regex ? regex.test(location.pathname) : false
        if (childActive) {
            match = true
        }
    })
    return match
}