import * as React from 'react';
import {GatsbyBrowser} from "gatsby";
import { Analytics } from '@vercel/analytics/react';
import {Provider as BalancerProvider} from "react-wrap-balancer";
import {isAnalyticsEnabled} from "./src/global/utils/vercel/analytics";
import RecaptchaProvider from "./src/global/context/recaptcha-provider";
import Layout from "./src/components/templates/layout/layout";
import PostHogWrapper from "./src/global/context/posthog-provider";

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({element, pathname}) => {

    return (
        <>
            <PostHogWrapper>
                <RecaptchaProvider>
                    <BalancerProvider>
                      {element}
                    </BalancerProvider>
                </RecaptchaProvider>
            </PostHogWrapper>
            {isAnalyticsEnabled && <Analytics/>}
        </>
    );
};

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({element, props }) => {
    return (
        <Layout props={props}>
            {element}
        </Layout>
    );
};