import * as React from "react";
import IconButton from "@mui/material/IconButton";
import {ExpandLess, ExpandMore, Menu as MenuIcon} from "@mui/icons-material";
import Menu from "@mui/material/Menu";
import {MenuItem} from "@atoms/mui/menuItem";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {Collapse, List, ListItemText, Stack} from "@mui/material";
import {getSlug, matchGatsbyPath, matchGatsbySubPath} from "@global/utils/regex/linkRegex";
import {useLocation} from "@reach/router";
import {Link, ListItemButton} from "gatsby-material-ui-components";


const TabletNavListItem: React.FunctionComponent<{
    link: Queries.StrapiNavigation,
    path: string | null,
    handleClose: any
}> = ({
          link,
          path,
          handleClose,
      }) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const location = useLocation()
    const active = matchGatsbySubPath(link, location)

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <>
            <MenuItem
                selected={active}
                key={link.id}
                //to={path ?? "#"}
                onClick={handleClick}
            >
                <Typography component={"span"}>{link.title}</Typography>
                {link.navigationChildren && link.navigationChildren.length > 0 &&
                    <IconButton sx={{ml: "auto"}} onClick={handleClick}>{open ? <ExpandLess/> :
                        <ExpandMore/>}</IconButton>}
            </MenuItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {link.navigationChildren && link.navigationChildren.map((childLink, index) => {
                        if (!childLink) {
                            return
                        }
                        const childPath = getSlug(childLink)
                        const regex = childPath && matchGatsbyPath(childPath)
                        const childActive = regex ? regex.test(location.pathname) : false
                        return (
                            <ListItemButton
                                key={index}
                                selected={childActive}
                                sx={{
                                    pl: 4,
                                    pb: 2,
                                    borderRadius: "0 25px 25px 0",
                                    "&.Mui-selected": {
                                        backgroundColor: "var(--mui-palette-secondary-main)",
                                        "& div": {
                                            color: "var(--mui-palette-secondary-contrastText)"
                                        }
                                    },
                                    "&.Mui-selected:hover": {
                                        backgroundColor: "var(--mui-palette-primary-main)",
                                        "& span": {
                                            color: "var(--mui-palette-common-white)"
                                        }
                                    }
                                }}
                            >
                                <Link
                                    onClick={handleClose}
                                    to={childPath ?? undefined}
                                    sx={{
                                        display: "flex",
                                        textDecoration: "none",
                                        width: "100%",
                                        height: 25,
                                        lineHeight: 1.3
                                    }}
                                >
                                    <ListItemText primary={childLink.title}/>
                                </Link>
                            </ListItemButton>
                        )
                    })}
                </List>
            </Collapse>
        </>
    )
}
const TabletNav: React.FunctionComponent<{
    links: Queries.StrapiNavigation[],
    handleOpen: (event: React.MouseEvent<HTMLElement>) => void,
    handleClose?: () => void
    anchor: null | HTMLElement
}> = ({links, handleOpen, handleClose, anchor}) => {
    const location = useLocation()

    return (
        <Box component="nav"
             sx={{flexGrow: 0, display: {xs: 'none', sm: links.length > 3 ? 'flex' : 'none', lg: 'none'}}}>
            <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpen}
                color="inherit"
            >
                <MenuIcon/>
            </IconButton>
            <Menu
                id="tablet nav menu"
                anchorEl={anchor}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                open={Boolean(anchor)}
                onClose={handleClose}
                sx={{
                    display: {xs: "none", sm: 'block', lg: 'none'},
                    backgroundColor: "rgba(0,0,0, .2)"
                }}
                PaperProps={{
                    sx: {
                        display: "flex",
                        alignItems: "center",
                        minWidth: 300,
                        backgroundColor: "var(--mui-palette-background-default)"
                    }
                }}
                MenuListProps={{
                    sx: {
                        width: "100%"
                    }
                }}
            >
                <Stack spacing={1} pr={2} pt={4} pb={2}>
                    {links.map((link) => {
                        const path = getSlug(link)
                        const regex = path && matchGatsbyPath(path)
                        const active = regex ? regex.test(location.pathname) : false
                        if (link.navigationChildren && link.navigationChildren?.length > 0) {
                            return (
                                <TabletNavListItem key={link.id} link={link} path={path} handleClose={handleClose}/>
                            )
                        }
                        if (!link.menuAttached || link.parentNode?.id) {
                            return
                        }
                        return (
                            <MenuItem
                                selected={active}
                                key={link.id}
                                to={path ?? undefined}
                                onClick={handleClose}
                                sx={{
                                    py: 2,
                                    borderRadius: "0 25px 25px 0",
                                    "&.Mui-selected": {
                                        backgroundColor: "var(--mui-palette-secondary-main)",
                                        "& span": {
                                            color: "var(--mui-palette-secondary-contrastText)"
                                        }
                                    },
                                    "&.Mui-selected:hover": {
                                        backgroundColor: "var(--mui-palette-primary-main)",
                                        "& span": {
                                            color: "var(--mui-palette-common-white)"
                                        }
                                    }
                                }}
                            >
                                <Typography component={"span"}>{link.title}</Typography>
                            </MenuItem>
                        )
                    })}
                </Stack>
            </Menu>
        </Box>
    )
}

export default TabletNav;