import React from "react";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";

interface RecaptchaProviderProps {
    children?: React.ReactNode
}

const RecaptchaProvider = ({children}: RecaptchaProviderProps) => {
    if (!process.env.GATSBY_GOOGLE_RECAPTCHA_V3_ENABLED || !process.env.GATSBY_GOOGLE_RECAPTCHA_V3_CLIENT_SECRET) {
        return children
    }
    return (
        <>
            <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_GOOGLE_RECAPTCHA_V3_CLIENT_SECRET}
                                     language="fr_CH">
                {children}
            </GoogleReCaptchaProvider>
        </>
    )
}

export default RecaptchaProvider