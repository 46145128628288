exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-strapi-article-slug-tsx": () => import("./../../../src/pages/blog/{StrapiArticle.slug}.tsx" /* webpackChunkName: "component---src-pages-blog-strapi-article-slug-tsx" */),
  "component---src-pages-blog-strapi-topic-slug-index-tsx": () => import("./../../../src/pages/blog/{StrapiTopic.slug}/index.tsx" /* webpackChunkName: "component---src-pages-blog-strapi-topic-slug-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-portal-[company]-[route]-tsx": () => import("./../../../src/pages/portal/[company]/[route].tsx" /* webpackChunkName: "component---src-pages-portal-[company]-[route]-tsx" */),
  "component---src-pages-portal-[company]-index-tsx": () => import("./../../../src/pages/portal/[company]/index.tsx" /* webpackChunkName: "component---src-pages-portal-[company]-index-tsx" */),
  "component---src-pages-portal-[company]-order-[id]-tsx": () => import("./../../../src/pages/portal/[company]/order/[id].tsx" /* webpackChunkName: "component---src-pages-portal-[company]-order-[id]-tsx" */),
  "component---src-pages-portal-index-tsx": () => import("./../../../src/pages/portal/index.tsx" /* webpackChunkName: "component---src-pages-portal-index-tsx" */),
  "component---src-pages-restaurants-strapi-place-slug-tsx": () => import("./../../../src/pages/restaurants/{StrapiPlace.slug}.tsx" /* webpackChunkName: "component---src-pages-restaurants-strapi-place-slug-tsx" */),
  "component---src-pages-restaurants-strapi-restaurant-slug-tsx": () => import("./../../../src/pages/restaurants/{StrapiRestaurant.slug}.tsx" /* webpackChunkName: "component---src-pages-restaurants-strapi-restaurant-slug-tsx" */),
  "component---src-pages-strapi-bar-page-slug-index-tsx": () => import("./../../../src/pages/{StrapiBarPage.slug}/index.tsx" /* webpackChunkName: "component---src-pages-strapi-bar-page-slug-index-tsx" */),
  "component---src-pages-strapi-blog-page-slug-index-tsx": () => import("./../../../src/pages/{StrapiBlogPage.slug}/index.tsx" /* webpackChunkName: "component---src-pages-strapi-blog-page-slug-index-tsx" */),
  "component---src-pages-strapi-page-slug-index-tsx": () => import("./../../../src/pages/{StrapiPage.slug}/index.tsx" /* webpackChunkName: "component---src-pages-strapi-page-slug-index-tsx" */),
  "component---src-pages-strapi-restaurant-page-slug-index-tsx": () => import("./../../../src/pages/{StrapiRestaurantPage.slug}/index.tsx" /* webpackChunkName: "component---src-pages-strapi-restaurant-page-slug-index-tsx" */)
}

