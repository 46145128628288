import Box from "@mui/material/Box";
import * as React from "react";
import {Dispatch, SetStateAction} from "react";
import {Button} from "gatsby-material-ui-components";
import {getSlug, matchGatsbyPath} from "@global/utils/regex/linkRegex";
import {useLocation} from "@reach/router";
import NavList from "@atoms/layout/navList";

const Nav: React.FunctionComponent<{
    links: Queries.StrapiNavigation[],
    handleClose?: () => void,
    setHover: Dispatch<SetStateAction<boolean>>
}> = ({
          links,
          handleClose,
          setHover
      }) => {
    const location = useLocation()

    return (
        <Box
            component="nav"
            sx={{flexGrow: 1, display: {xs: 'none', md: links.length > 3 ? 'none' : 'flex', lg: 'flex'}}}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            {links.map((link) => {
                const path = getSlug(link)
                const regex = path && matchGatsbyPath(path)
                const active = regex ? regex.test(location.pathname) : false
                if (link.navigationChildren && link.navigationChildren?.length > 0) {
                    return (
                        <NavList key={link.id} link={link} path={path}/>
                    )
                }
                if (!link.menuAttached || link.parentNode?.id) {
                    return
                }
                return (
                    <Button
                        aria-label={`navigate to ${link.title}`}
                        key={link.id}
                        to={path ?? undefined}
                        onClick={handleClose}
                        sx={{
                            my: 2,
                            fontWeight: active ? 'bold' : 'normal',
                            color: active ? 'highContrast.main' : 'primary.contrastText',
                            display: 'block',
                            textAlign: 'center',
                            height: 37
                        }}
                    >
                        {link.title}
                    </Button>
                )
            })}
        </Box>
    )
}

export default Nav;