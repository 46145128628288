import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import {Logout, Menu as MenuIcon} from '@mui/icons-material';
import Container from '@mui/material/Container';
import ThemeSwitcher from "@atoms/layout/themeSwitcher";
import Nav from "@atoms/layout/nav";
import TabletNav from "@atoms/layout/tabletNav";
import MobileNav from "@atoms/layout/mobileNav";
import Logo from '@molecules/logo';
import {Stack, useScrollTrigger} from "@mui/material";
import {graphql, navigate, useStaticQuery} from "gatsby";
import KookoAppButton from '@atoms/layout/kookoAppButton';
import AlertDialog from "@atoms/mui/alertDIalog/alertDialog";
import Typography from "@mui/material/Typography";

interface HeaderProps {
    isPortal?: boolean
    isEvent?: boolean
}

const Header = ({isPortal, isEvent}: HeaderProps) => {//ToDo: move states to respective components (TabletNav & UserMenu) after replacing nav items with links
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const [mobileOpen, setMobileOpen] = React.useState<boolean>(false);
    const [hover, setHover] = React.useState<boolean>(false);
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 100,
    });

    const global = useStaticQuery(graphql`
      query HeaderQuery {
          allStrapiNavigation {
              nodes {
                  id
                  _id
                  title
                  path
                  menuAttached
                  noLink
                  type
                  order
                  parentNode {
                    id
                  }
                  related {
                      slug
                  }
                  navigationChildren {
                      id
                      _id
                      title
                      path
                      menuAttached
                      noLink
                      related {
                          slug
                      }
                  }
              }
          }
      }
  `)

    const links = global.allStrapiNavigation.nodes.sort((a: Queries.StrapiNavigation, b: Queries.StrapiNavigation) => {
        if (a.menuAttached && b.menuAttached && a.order && b.order) {
            return a.order - b.order
        }
        if (a.menuAttached && !b.menuAttached) {
            return -1
        }
        if (!a.menuAttached && b.menuAttached) {
            return 1
        }
    })

    function handleDrawerToggle() {
        setMobileOpen((prevState) => !prevState);
    }

    function handleOpenNavMenu(event: React.MouseEvent<HTMLElement>) {
        setAnchorElNav(event.currentTarget);
    }

    function handleOpenUserMenu(event: React.MouseEvent<HTMLElement>) {
        setAnchorElUser(event.currentTarget);
    }

    function handleCloseNavMenu() {
        setAnchorElNav(null);
    }

    function handleCloseUserMenu() {
        setAnchorElUser(null);
    }

    return (
        <AppBar
            elevation={(trigger || hover) ? 4 : 0}
            sx={{
                position: "fixed",
                backgroundColor: (trigger || hover) ? "var(--mui-palette-tertiary-semiTransparent)" : "transparent",
                backdropFilter: (trigger || hover) ? "blur(4px)" : "unset",
                transition: "0.3s ease"
            }}
        >
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    {!isPortal &&
                        <>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                                sx={{mr: 2, display: {sm: 'none'}}}
                            >
                                <MenuIcon/>
                            </IconButton>
                            <MobileNav links={links} handleDrawerToggle={handleDrawerToggle} open={mobileOpen}
                                       drawerWidth={"75%"}/>
                            <TabletNav links={links} handleOpen={handleOpenNavMenu} handleClose={handleCloseNavMenu}
                                       anchor={anchorElNav}/>
                        </>
                    }
                    <Logo/>
                    <Box flexGrow={1}/>
                    {!isPortal && <Nav links={links} handleClose={handleCloseNavMenu} setHover={setHover}/>}
                    <Stack direction={"row"} spacing={1} sx={{alignSelf: "flex-start", pt: {xs: 1, md: 1}}}>
                        <KookoAppButton fullWidth header highlight={trigger} sx={{display: {xs: "none", md: "flex"}}} isPortal={isPortal} isEvent={isEvent}/>
                        {/*<UserMenu settings={settings} handleOpen={handleOpenUserMenu} handleClose={handleCloseUserMenu} anchor={anchorElUser}/>*/}
                        <ThemeSwitcher/>
                        {isPortal &&
                            <AlertDialog
                                title={
                                    <Stack direction="row" spacing={1} alignItems={"center"}>
                                        <Logout/>
                                        <Typography fontSize={"1.2em"}>Retour au site web</Typography>
                                    </Stack>
                                }
                                iconButton={<Logout/>}
                                okText={"Aller au site web"}
                                cancelText={"Rester sur le portail"}
                                okHandler={() => navigate("/")}
                            >
                                Vous êtes sur le point de quitter votre portail entreprise êtes-vous sur de vouloir
                                continuer ?
                            </AlertDialog>
                        }
                    </Stack>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default Header;
