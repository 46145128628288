import * as React from 'react';
import {graphql, useStaticQuery} from "gatsby";
import Grid from "@mui/material/Unstable_Grid2";
import PageListMenu from "@atoms/layout/pageListMenu";
import {Stack} from "@mui/material";
import PageListGrid from "@atoms/layout/pageListGrid";
import Logo from "@molecules/logo";
import Box from "@mui/material/Box";
import {Link} from "gatsby-material-ui-components";
import Typography from "@mui/material/Typography";

const Footer = () => {//ToDo: move states to respective components (TabletNav & UserMenu) after replacing nav items with links

    const global = useStaticQuery(graphql`
      query FooterQuery {
          allStrapiNavigation {
              nodes {
                  id
                  _id
                  title
                  path
                  menuAttached
                  noLink
                  type
                  order
                  parentNode {
                    id
                  }
                  related {
                      slug
                  }
                  navigationChildren {
                      id
                      _id
                      title
                      path
                      menuAttached
                      noLink
                      related {
                          slug
                      }
                  }
              }
          }
      }
  `)

    const links = global.allStrapiNavigation.nodes.sort((a: Queries.StrapiNavigation, b: Queries.StrapiNavigation) => {
        if (a.menuAttached && b.menuAttached && a.order && b.order) {
            return a.order - b.order
        }
        if (a.menuAttached && !b.menuAttached) {
            return -1
        }
        if (!a.menuAttached && b.menuAttached) {
            return 1
        }
    })

    // @ts-ignore
    return (
        <Stack component={"footer"} minHeight={300} sx={{background: "var(--mui-palette-bannerBlog-main)"}} px={4}>
            <Grid container py={4} sx={{width: 1200, maxWidth: "100%", margin: "auto"}}>
                <Grid
                    xs={12}
                    md={6}
                    lg={2}
                    sx={{order: {xs: 0, md: 1, lg: 0}}}
                >
                    <Stack spacing={2} alignItems={{xs: "center", md: "flex-start"}}>
                        <Box><Logo/></Box>
                        <Box display={{xs: "block", md: "none"}}><PageListMenu links={links}/></Box>
                    </Stack>
                </Grid>
                <Grid
                    xs={0}
                    md={12}
                    lg={10}
                    sx={{
                        display: {xs: "none", md: "block"},
                        order: {xs: 1, md: 0, lg: 1},
                        flexGrow: 1
                    }}
                >
                    <Stack
                        alignItems={"flex-end"}
                        py={3}
                    >
                        <PageListGrid links={links}/>
                    </Stack>
                </Grid>
            </Grid>
            <Stack
                width={1200}
                maxWidth={"100%"}
                margin={"auto"}
                direction={"row"}
                justifyContent={{xs: "center", md: "space-between"}}
                alignItems={"center"}
                flexWrap={"wrap"}
                pb={4}
                columnGap={2}
                rowGap={2}
            >
                <Typography fontSize={12}>© 2023 Kooko sàrl - tous droits réservés</Typography>
                <Stack direction={"row"} spacing={2}>
                    <Link color="secondary" fontSize={12}
                          to={"https://supabase.kooko.dev/storage/v1/object/public/strapi/20240404%20CG%20Kooko_1712331223758.pdf"}>Conditions
                        Générales et Charte de Confidentialité</Link>
                </Stack>
            </Stack>
        </Stack>
    );
}
export default Footer;
