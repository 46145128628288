import * as React from "react";
import {SupportedColorScheme, useColorScheme} from "@mui/material";
import {ModeNight, WbSunny} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
// @ts-ignore
import {Helmet} from "react-helmet";
import kookoGreenDark from "gatsby-plugin-mui-joy-emotion/colors/kookoGreenDark";
import kookoGreenLight from "gatsby-plugin-mui-joy-emotion/colors/kookoGreenLight";
import Box from "@mui/material/Box";

const useEnhancedEffect = typeof window !== 'undefined' ? React.useLayoutEffect : React.useEffect;

const ThemeSwitcher = () => {
    const {mode, setMode, systemMode} = useColorScheme();
    const [isMounted, setIsMounted] = React.useState<boolean>(false)
    const [colorScheme, setColorScheme] = React.useState<SupportedColorScheme | "system" | null>(systemMode ?? null)

    useEnhancedEffect(() => {
        setIsMounted(true)
    }, [])

    React.useEffect(() => {
        if (systemMode) {
            setMode(systemMode)
        }
    }, [systemMode])

    React.useEffect(() => {
        if (isMounted && mode) {
            setColorScheme(mode)
        }
    }, [isMounted, mode])

    return (
        <Box display={"flex"} alignItems={"center"}>
            <Helmet>
                <meta name="theme-color"
                      content={mode === "dark" ? kookoGreenDark.background : kookoGreenLight.background}/>
            </Helmet>
            <IconButton
                aria-label={`switch theme mode`}
                color="secondary"
                //variant="contained"
                //sx={{opacity: ssrMode ? 1: 0}}
                onClick={() => setMode(mode === 'dark' ? 'light' : 'dark')}
            >
                {colorScheme === 'dark' ?
                    <ModeNight
                        sx={{
                            opacity: colorScheme ? 1 : 0,
                            transition: "opacity ease .3s"
                        }}/> :
                    <WbSunny
                        sx={{
                            opacity: colorScheme ? 1 : 0,
                            transition: "opacity ease .3s",
                        }}
                    />
                }
            </IconButton>
        </Box>

    );
}

export default ThemeSwitcher