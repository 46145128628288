import * as React from "react";
import Restaurant from "@mui/icons-material/Restaurant";
import {Button} from "gatsby-material-ui-components";
import {SxProps, useColorScheme} from "@mui/material";
import {useLocation} from "@reach/router";

type KookoAppButtonProps = {
    fullWidth?: boolean
    header?: boolean
    sx?: SxProps
    highlight?: boolean
    isPortal?: boolean
    isEvent?: boolean
}
const useEnhancedEffect = typeof window !== 'undefined' ? React.useLayoutEffect : React.useEffect;
const publicLink = "/contact"

function extractPath(path: string) {
    // Split the URL path by '/'
    const segments = path.split('/');

    // Find the index of 'portal'
    const portalIndex = segments.indexOf('portal');

    // If 'portal' is found and there are segments following 'portal' and the company name slug
    if (portalIndex !== -1 && segments.length > portalIndex + 1) {
        // Rejoin the segments from the company name slug onwards and return
        return `${segments.slice(0, portalIndex + 2).join('/')}`;
    } else {
        throw new Error('Invalid path format');
    }
}

function getEventOrderPath(location: Location) {
    const path = location.pathname;
    const orderPath = path.includes("order") ? "" : "order"
    const search = location.search;

    return `${path}${orderPath}${search}`;
}

const KookoAppButton = ({fullWidth, header, sx, highlight = true, isPortal, isEvent}: KookoAppButtonProps) => {
    const {mode} = useColorScheme();
    const [collapse, setCollapse] = React.useState<boolean>(false)
    const [isMounted, setIsMounted] = React.useState<boolean>(false)
    const [colorScheme, setColorScheme] = React.useState<any>(null)
    const location = useLocation()
    const [link, setLink] = React.useState<string>(publicLink)

    useEnhancedEffect(() => {
        setIsMounted(true)
    }, [])

    React.useEffect(() => {
        if(isEvent && location.pathname !== ("/events/" || "/events")){
            const eventLink = getEventOrderPath(location)
            if(eventLink !== link){
                setLink(eventLink)
            }
        } else {
            if(link !== publicLink){
                setLink(publicLink)
            }
        }
    }, [isEvent, location.pathname])


    React.useEffect(() => {
        if (isMounted) {
            setColorScheme(mode)
        }
    }, [isMounted, mode])

    React.useEffect(() => {
        if (!fullWidth && !collapse && isMounted) {
            setTimeout(() => {
                setCollapse(true)
            }, 6000)
        }
    }, [fullWidth, isMounted])

    if (isPortal) {
        return <></>
    }

    return (
        <>
            <Button
                aria-label={"go to app"}
                to={link}
                //@ts-ignore
                target={"_blank"}
                variant={highlight ? "contained" : "outlined"}
                color={highlight ? "primary" : "secondary"}
                size={fullWidth ? "medium" : "large"}
                sx={{
                    ...sx,
                    opacity: colorScheme ? 1 : 0,
                    position: header ? "static" : "fixed",
                    bottom: 15,
                    right: 15,
                    mr: 0,
                    p: 2,
                    zIndex: 99,
                    minWidth: 48,
                    width: (fullWidth || !collapse) ? 210 : 54,
                    height: fullWidth ? 48 : 54,
                    transition: "width ease .3s, opacity ease .3s",
                    "& .MuiButton-startIcon": {
                        mr: !fullWidth && collapse ? 0 : 1,
                    }
                }}
                startIcon={<Restaurant/>}
            >
                {(fullWidth || !collapse) && "Contactez-nous"}
            </Button>
        </>
    )
}

export default KookoAppButton