import * as React from "react";
import {IconButtonProps} from "@mui/material/IconButton/IconButton";
import {IconButton} from "gatsby-material-ui-components";

const CustomIconButton: React.FunctionComponent<{
    children: any,
    iconButtonProps?: IconButtonProps,
    iconButtonStyle: IconButtonProps['sx'],
    large?: boolean
}> = ({
          children,
          iconButtonProps,
          iconButtonStyle,
          large
      }) => {
    const svg = React.cloneElement(children, {width: large ? children.width : 24, height: 24})

    return (
        <IconButton aria-label="svg button" size={"large"} {...iconButtonProps}
                    sx={{borderRadius: large ? 4 : "50%", ...iconButtonStyle}}>
            {svg}
        </IconButton>
    )
}

export default CustomIconButton;