import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {OpenInFull} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";

interface BaseAlertDialogProps {
    title: React.ReactNode | string
    children: React.ReactNode | string
    okText?: string
    okHandler?: () => any
    cancelText?: string
    cancelHandler?: () => any
    buttonText?: string
    icon?: React.ReactNode
    isOpen?: boolean
}

interface AlertDialogWithButton extends BaseAlertDialogProps {
    button: React.ReactNode;
    iconButton?: never; // Ensures that if button is provided, iconButton cannot be
}

interface AlertDialogWithIconButton extends BaseAlertDialogProps {
    button?: never; // Ensures that if iconButton is provided, button cannot be
    iconButton: React.ReactNode;
}

type AlertDialogProps = AlertDialogWithButton | AlertDialogWithIconButton;


const AlertDialog = ({
                         title,
                         children,
                         okText,
                         okHandler,
                         cancelText,
                         cancelHandler,
                         button,
                         buttonText,
                         iconButton,
                         icon,
                         isOpen
                     }: AlertDialogProps) => {
    const [open, setOpen] = React.useState<boolean>(isOpen ?? false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        if (okHandler) okHandler()
        if (cancelHandler) cancelHandler()
        setOpen(false);
    };

    return (
        <React.Fragment>
            {button &&
                <Button variant="outlined" onClick={handleClickOpen} color="secondary">
                    {buttonText ?? "Open alert dialog"}
                </Button>
            }
            {iconButton &&
                <IconButton onClick={handleClickOpen} color="secondary">
                    <>{iconButton ?? <OpenInFull/>}</>
                </IconButton>
            }
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {typeof children === "string" ?
                    <DialogTitle id="alert-dialog-title">
                        {title}
                    </DialogTitle> : title ??
                    <DialogTitle id="alert-dialog-title">
                        Add your title here
                    </DialogTitle>
                }
                <DialogContent>
                    {typeof children === "string" ?
                        <DialogContentText id="alert-dialog-description">
                            {children}
                        </DialogContentText> : children ??
                        <DialogContentText id="alert-dialog-description">
                            Let Google help apps determine location. This means sending anonymous
                            location data to Google, even when no apps are running.
                        </DialogContentText>
                    }
                </DialogContent>
                <DialogActions>
                    {cancelText &&
                        <Button onClick={handleClose}>
                            {cancelText}
                        </Button>
                    }
                    <Button onClick={handleClose} autoFocus variant={"outlined"}>
                        {okText ?? "Agree"}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}


export default AlertDialog