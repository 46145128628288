import * as React from "react";
import CustomIconButton from "@atoms/shared/svg";
import LogoFile from "@global/resources/svg/kooko.inline.svg";

interface LogoProps {
    isPortal?: boolean
}

const Logo = ({isPortal}: LogoProps) => {
    return (
        <CustomIconButton
            aria-label="go to homepage"
            large
            iconButtonProps={{
                //@ts-ignore
                to: isPortal && "/",
                disableRipple: true
            }}
            iconButtonStyle={{paddingLeft: {xs: 2, md: 0}, alignSelf: "flex-start", paddingTop: {xs: 2, md: 2.5}}}
        >
            <LogoFile style={{fill: "#009f79"}}/>
        </CustomIconButton>
    )
}

export default Logo;