module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mui-joy-emotion/gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Kooko","short_name":"Kooko","description":"Les meilleurs restaurants de la région livrés en vélo cargo chez vous ou au bureau.","start_url":"/","background_color":"#009f79","display":"standalone","orientation":"portrait","icon":"./src/global/resources/images/icon.png","icons":[{"src":"./src/global/resources/images/icon.png","sizes":"196x196","type":"image/png","purpose":"any"},{"src":"./src/global/resources/images/manifest-icon.png","sizes":"196x196","type":"image/png","purpose":"maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9187dc50f18bec6f47f122bd70fe51be"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
